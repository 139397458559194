import React, { useEffect } from 'react';
import { Container, Row, Col, Navbar } from 'react-bootstrap';
import { Routes } from './Routes';
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom';
import './App.scss';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <span className="bk1"></span>
        <span className="bk2"></span>
        <span className="bk3"></span>
        <span className="bk4"></span>
        <span className="bk5"></span>
        <Container fluid>
          <Navbar variant="dark">
            <Navbar.Brand>
              <Link to="/">
                <img
                  title="Omniverse Home"
                  alt="home"
                  width="100%"
                  src="/Logo.png"
                />
              </Link>
            </Navbar.Brand>
          </Navbar>
          <Routes />
          <Row>
            <Col>Contact: mothership at omniversemusic dot com</Col>
          </Row>
        </Container>
      </div>
    </Router>
  );
}

export default App;
