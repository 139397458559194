import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Album, AlbumLinks } from '../Album';

const IMG = '/WorldThatFollows-Bubble.png';
const TITLE = 'Worlds That Follow';
const PAGE = 'worlds';
const BODY = (
  <>
    <p>
      The latest album from Omniverse explores the worlds we know and the worlds
      we can barely imagine.
    </p>
  </>
);

const BANDCAMP = 'https://omn1verse.bandcamp.com/album/worlds-that-follow';
const APPLE = 'https://music.apple.com/us/album/worlds-that-follow/1541405930';
const SPOTIFY = 'https://open.spotify.com/album/3TNOZM8ysMZPR24ml1PZQM';
const SOUNDCLOUD = null;
const AMAZON =
  'https://www.amazon.com/Worlds-That-Follow-Omniverse/dp/B08NZHH56Q/ref=sr_1_1?dchild=1&keywords=omniverse+worlds+that+follow&qid=1606238246&sr=8-1';

const FOOTER = (
  <iframe
    style={{ border: 0, width: '100%', height: '406px' }}
    src="https://bandcamp.com/EmbeddedPlayer/album=523621892/size=large/bgcol=ffffff/linkcol=0687f5/artwork=small/transparent=true/"
    seamless
  >
    <a href="https://omn1verse.bandcamp.com/album/worlds-that-follow">
      Worlds That Follow by Omniverse
    </a>
  </iframe>
);

const links = [];

BANDCAMP &&
  links.push({
    title: 'Bandcamp',
    href: BANDCAMP,
  });
APPLE &&
  links.push({
    title: 'Apple Music',
    href: APPLE,
  });
SPOTIFY &&
  links.push({
    title: 'Spotify',
    href: SPOTIFY,
  });
SOUNDCLOUD &&
  links.push({
    title: 'SoundCloud',
    href: SOUNDCLOUD,
  });
AMAZON &&
  links.push({
    title: 'Amazon',
    href: AMAZON,
  });

export const Worlds = () => (
  <>
    <Row>
      <Col xs={12} sm={8} md={8} lg={8} xl={8}>
        <Album img={IMG} title={TITLE} page={PAGE} body={BODY} />
      </Col>
      {links.length ? (
        <Col xs={12} sm={4} md={4} lg={4} xl={4}>
          <AlbumLinks links={links} />
        </Col>
      ) : null}
    </Row>
    <Row>
      <Col md={8} lg={8} xl={8}>
        {FOOTER}
      </Col>
      <Col xs={0} sm={0} md={2} lg={2} xl={2} />
    </Row>
  </>
);
