import React from 'react';
import { Row, Col, Jumbotron } from 'react-bootstrap';
import { ReleaseCard } from './Release';
import { ListGroup } from 'react-bootstrap';

const ColRelease = ({ children }) => (
  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
    {children}
  </Col>
);

export const Catalog = () => (
  <>
    <Row style={{ maxWidth: '974px' }}>
      <Col>
        <Jumbotron>
          <a href="worlds">
            <h2 className="worlds" aria-label="Worlds That Follow">
              <img
                width="100%"
                src="/worlds.png"
                title="Worlds That Follow album"
                alt="Worlds That Follow album"
              />
            </h2>
            <div className="preview" />
          </a>
        </Jumbotron>
      </Col>
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="hotlinks-container"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '2rem',
          }}
          className="hotlinks"
        >
          <ListGroup horizontal>
            <ListGroup.Item key="bandcamp">
              <a href="https://omn1verse.bandcamp.com/album/worlds-that-follow">
                Bandcamp
              </a>
            </ListGroup.Item>
            <ListGroup.Item key="spotify">
              <a href="https://open.spotify.com/album/3TNOZM8ysMZPR24ml1PZQM">
                Spotify
              </a>
            </ListGroup.Item>
            <ListGroup.Item key="apple">
              <a href="https://music.apple.com/us/album/worlds-that-follow/1541405930">
                Apple
              </a>
            </ListGroup.Item>
            <ListGroup.Item key="amazon">
              <a href="https://www.amazon.com/Worlds-That-Follow-Omniverse/dp/B08NZHH56Q/ref=sr_1_1?dchild=1&keywords=omniverse+worlds+that+follow&qid=1606238246&sr=8-1">
                Amazon
              </a>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <h2>Releases</h2>
      </Col>
    </Row>
    <Row>
      <ColRelease>
        <ReleaseCard
          img="https://is1-ssl.mzstatic.com/image/thumb/Music2/v4/7c/fd/d1/7cfdd1ac-666a-dd56-1989-b1c013646971/889211705320.jpg/1000x1000bb-60.jpg"
          title="Bambooky"
          page="bambooky"
          id="bambooky"
          links={[
            {
              title: 'Spotify',
              href:
                'https://open.spotify.com/album/4xe5Fi8Oe7FNE9HPzRFjKX?si=RVcP9E5oSZq74pmEyDWMJA',
            },
            {
              title: 'Apple',
              href:
                'https://music.apple.com/us/album/bambooky-single/1031454787',
            },
          ]}
        />
      </ColRelease>
      <ColRelease>
        <ReleaseCard
          img="/crypto-set.png"
          title="Cryptozoology Megalodon Set"
          page="crypto2"
          id="crypto2"
          links={[
            {
              title: 'Bandcamp',
              href:
                'https://omn1verse.bandcamp.com/album/cryptozoology-megalodon-set',
            },
            {
              title: 'Spotify',
              href:
                'https://open.spotify.com/album/3CHmMAJhS7MeJs2HFYod6Z?si=Cbqr7sNwRBONUg5cbNSGFg',
            },
          ]}
        />
      </ColRelease>
      <ColRelease>
        <ReleaseCard
          img="https://is3-ssl.mzstatic.com/image/thumb/Music/v4/37/81/f2/3781f250-30bc-d10c-c04b-86d9e3826431/884501963091.jpg/1000x1000bb-60.jpg"
          title="The Ruiner (with Lucy Bland)"
          page="ruiner"
          id="ruiner"
          links={[
            {
              title: 'Spotify',
              href:
                'https://open.spotify.com/album/1fD4PVv41GtDSXqnZ25wAd?si=NrQenT7bRTqTfUPCxEbO7w',
            },
            {
              title: 'Apple',
              href: 'https://music.apple.com/us/album/the-ruiner/725977203',
            },
          ]}
        />
      </ColRelease>
      <ColRelease>
        <ReleaseCard
          img="https://is5-ssl.mzstatic.com/image/thumb/Music/v4/00/8d/f2/008df20a-4a6e-fe57-850b-09efdc1f7e4b/884501611107.jpg/540x540bb-60.jpg"
          title="Cryptozoology"
          id="crypto"
          page="crypto"
          links={[
            {
              title: 'Spotify',
              href:
                'https://open.spotify.com/album/0uYSEI9mRLXU4f6bm9Fuuv?si=8wjwuy1SSyy8bLuhXVSdmg',
            },
            {
              title: 'Apple',
              href:
                'https://music.apple.com/us/album/cryptozoology-ep/562780402',
            },
          ]}
        />
      </ColRelease>
      <ColRelease>
        <ReleaseCard
          img="/rewrites.jpg"
          title="Brown Sky Rewrites (with Lucy Bland)"
          id="rewrites"
          links={[
            {
              title: 'Spotify',
              href:
                'https://open.spotify.com/album/0us1r4WDCOu17Jw3TS1PF0?si=qfKCt1zeR5iu_gS3DH2Q-g',
            },
            {
              title: 'Apple',
              href:
                'https://music.apple.com/us/album/brown-sky-rewrites/362736507',
            },
          ]}
        />
      </ColRelease>
      <ColRelease>
        <ReleaseCard
          img="http://lucybland.com/assets/sealevel-large.jpg"
          title="Down to Sea Level (with Lucy Bland)"
          id="sealevel"
          links={[
            {
              title: 'Spotify',
              href:
                'https://open.spotify.com/album/1TsJznkC8zGgiymMDAHmzW?si=hkvn3QzfT4mJptFSudHOhw',
            },
            {
              title: 'Apple',
              href:
                'https://music.apple.com/us/album/down-to-sea-level/276393977',
            },
          ]}
        />
      </ColRelease>
      <ColRelease>
        <ReleaseCard
          img="https://is5-ssl.mzstatic.com/image/thumb/Features/6a/1a/43/dj.qnmrzbxm.tif/1000x1000bb-60.jpg"
          title="Dandelion"
          id="dandelion"
          page="dandelion"
          links={[
            {
              title: 'Spotify',
              href:
                'https://open.spotify.com/album/3jPrJWXm0XLPraarMu8FdU?si=y4YNXQtETBa8suJ_qC-4Pw',
            },
            {
              title: 'Apple',
              href: 'https://music.apple.com/us/album/dandelion/4400197',
            },
          ]}
        />
      </ColRelease>
      <ColRelease>
        <ReleaseCard
          img="https://is3-ssl.mzstatic.com/image/thumb/Music/y2005/m07/d25/h23/mzi.trkjczzj.tif/1000x1000bb-60.jpg"
          title="First Recital (with Lucy Bland)"
          id="first"
          links={[
            {
              title: 'Spotify',
              href:
                'https://open.spotify.com/album/3z8shWqrdwqtX1pbwtyPIl?si=rRP9zdiWTFucrjpJUcB8NA',
            },
            {
              title: 'Apple',
              href:
                'https://music.apple.com/us/album/lucy-blands-first-recital/76905250',
            },
          ]}
        />
      </ColRelease>
    </Row>
  </>
);
