import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Album, AlbumLinks } from '../Album'

export const Crypto2 = () => (
  <>
    <Row>
      <Col xs={12} sm={8} md={8} lg={8} xl={8}>
        <Album
          img="../crypto-set.png"
          title="Cryptozoology Megalodon Set"
          page="crypto2"
          body={
            <>
              <p>
                The 5 original Cryptozoology species plus 5 alternate branches
                swirled together in a DJ set of over 40 minutes of music and
                sound design.
              </p>
              <p>Todd Wallar - instruments and production on most everything</p>
              <p>Chris Icasiano - percussion on some things</p>
              <p> Release date: January 2013</p>
            </>
          }
        />
      </Col>
      <Col xs={12} sm={4} md={4} lg={4} xl={4}>
        <AlbumLinks
          links={[
            {
              title: 'Bandcamp',
              href:
                'https://omn1verse.bandcamp.com/album/cryptozoology-megalodon-set',
            },
            {
              title: 'Apple Music',
              href:
                'https://music.apple.com/us/album/cryptozoology-magalodon-set/587718239',
            },
            {
              title: 'Spotify',
              href:
                'https://open.spotify.com/album/3CHmMAJhS7MeJs2HFYod6Z?si=Cbqr7sNwRBONUg5cbNSGFg',
            },
            {
              title: 'SoundCloud',
              href:
                'https://soundcloud.com/omniverse/cryptozoology-megalodon-set',
            },
          ]}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <iframe
          width="100%"
          height="166"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          title="listen on soundcloud"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/73326473&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
        ></iframe>
      </Col>
    </Row>
  </>
)
