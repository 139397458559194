import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Catalog } from './Catalog';
import { Bambooky } from './pages/Bambooky';
import { Crypto } from './pages/Crypto';
import { Crypto2 } from './pages/Crypto2';
import { Ruiner } from './pages/Ruiner';
import { Dandelion } from './pages/Dandelion';
import { Worlds } from './pages/Worlds';

function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        404 <code>{location.pathname}</code>
      </h3>
    </div>
  );
}
export const Routes = () => (
  <Switch>
    <Route path="/worlds">
      <Worlds />
    </Route>
    <Route path="/bambooky">
      <Bambooky />
    </Route>
    <Route path="/crypto2">
      <Crypto2 />
    </Route>
    <Route path="/crypto">
      <Crypto />
    </Route>
    <Route path="/ruiner">
      <Ruiner />
    </Route>
    <Route path="/dandelion">
      <Dandelion />
    </Route>
    <Route exact path="/">
      <Catalog />
    </Route>
    <Route path="*" status={404}>
      <NoMatch />
    </Route>
  </Switch>
);
