import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Album, AlbumLinks } from '../Album';

const IMG =
  'https://is5-ssl.mzstatic.com/image/thumb/Features/6a/1a/43/dj.qnmrzbxm.tif/1000x1000bb-60.jpg';
const TITLE = 'Dandelion';
const PAGE = 'dandelion';
const BODY = (
  <>
    <p>Omniverse debut album</p>
    <p>Now streaming in its entirety on Soundcloud.</p>
    <p>Release date: 2003</p>
  </>
);

const BANDCAMP = null;
const APPLE = 'https://music.apple.com/us/album/dandelion/4400197';
const SPOTIFY =
  'https://open.spotify.com/album/3jPrJWXm0XLPraarMu8FdU?si=y4YNXQtETBa8suJ_qC-4Pw';
const SOUNDCLOUD = 'https://soundcloud.com/omniverse/sets/dandelion-3';
const AMAZON =
  'https://www.amazon.com/Dandelion-Omniverse/dp/B0012KBCPO/ref=sr_1_1?dchild=1&keywords=omniverse+dandelion&qid=1604786964&sr=8-1';

const FOOTER = (
  <>
    <iframe
      width="100%"
      height="450"
      scrolling="no"
      frameborder="no"
      allow="autoplay"
      src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/2497174&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
    ></iframe>
  </>
);

export const Dandelion = () => (
  <>
    <Row>
      <Col xs={12} sm={8} md={8} lg={8} xl={8}>
        <Album img={IMG} title={TITLE} page={PAGE} body={BODY} />
      </Col>
      <Col xs={12} sm={4} md={4} lg={4} xl={4}>
        <AlbumLinks
          links={[
            BANDCAMP && {
              title: 'Bandcamp',
              href: BANDCAMP,
            },
            APPLE && {
              title: 'Apple Music',
              href: APPLE,
            },
            SPOTIFY && {
              title: 'Spotify',
              href: SPOTIFY,
            },
            SOUNDCLOUD && {
              title: 'SoundCloud',
              href: SOUNDCLOUD,
            },
            ,
            AMAZON && {
              title: 'Amazon',
              href: AMAZON,
            },
          ]}
        />
      </Col>
    </Row>
    <Row>
      <Col>{FOOTER}</Col>
    </Row>
  </>
);
