import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Album, AlbumLinks } from '../Album'

export const Bambooky = () => (
  <>
    <Row>
      <Col xs={12} sm={8} md={8} lg={8} xl={8}>
        <Album
          img="https://is1-ssl.mzstatic.com/image/thumb/Music2/v4/7c/fd/d1/7cfdd1ac-666a-dd56-1989-b1c013646971/889211705320.jpg/1000x1000bb-60.jpg"
          title="bambooky"
          showTitle={false}
          page="bambooky"
          body={
            <>
              <p>A project from Omniverse and Kiwai Lai</p>
              <p> Guitars and Electronics</p>
            </>
          }
        />
      </Col>
      <Col xs={12} sm={4} md={4} lg={4} xl={4}>
        <AlbumLinks
          links={[
            {
              title: 'Spotify',
              href:
                'https://open.spotify.com/album/4xe5Fi8Oe7FNE9HPzRFjKX?si=RVcP9E5oSZq74pmEyDWMJA',
            },
            {
              title: 'Apple Music',
              href:
                'https://music.apple.com/us/album/bambooky-single/1031454787',
            },
            {
              title: 'Amazon',
              href: 'https://www.amazon.com/dp/B014BHZRLE/ref=cm_sw_su_dp',
            },
            {
              title: 'YouTube',
              href:
                'https://www.youtube.com/watch?v=uE_GhuyMvks&list=OLAK5uy_kwD8fTA46gYgqJbRyy_s1xRdWhVTs9PmE',
            },
            {
              title: 'iTunes Store',
              href: 'https://itun.es/i6SJ8ZR',
            },
          ]}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <iframe
          width="100%"
          height="320"
          scrolling="no"
          frameBorder="no"
          title="listen on Soundcloud"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/140381186&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false"
        ></iframe>
      </Col>
    </Row>
  </>
)
