import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Album, AlbumLinks } from '../Album';

const IMG =
  'https://is5-ssl.mzstatic.com/image/thumb/Music/v4/00/8d/f2/008df20a-4a6e-fe57-850b-09efdc1f7e4b/884501611107.jpg/540x540bb-60.jpg';
const TITLE = 'Cryptozoology EP';
const PAGE = 'crypto';
const BODY = (
  <>
    <p>Five instrumentals based on mythical creatures.</p>
    <p>Todd Wallar - instruments and production on most everything</p>
    <p>Chris Icasiano - percussion</p>
  </>
);

const BANDCAMP = null;
const APPLE = 'https://music.apple.com/us/album/cryptozoology-ep/562780402';
const SPOTIFY =
  'https://open.spotify.com/album/0uYSEI9mRLXU4f6bm9Fuuv?si=8wjwuy1SSyy8bLuhXVSdmg';
const SOUNDCLOUD = 'https://soundcloud.com/omniverse/sets/cryptozoology';
const AMAZON =
  'https://www.amazon.com/Cryptozoology-by-Omniverse-2012-09-18/dp/B01AB9AF7C/ref=sr_1_1?dchild=1&keywords=omniverse+cryptozoology&qid=1604785359&sr=8-1';

const FOOTER = null;

export const Crypto = () => (
  <>
    <Row>
      <Col xs={12} sm={8} md={8} lg={8} xl={8}>
        <Album img={IMG} title={TITLE} page={PAGE} body={BODY} />
      </Col>
      <Col xs={12} sm={4} md={4} lg={4} xl={4}>
        <AlbumLinks
          links={[
            BANDCAMP && {
              title: 'Bandcamp',
              href: BANDCAMP,
            },
            APPLE && {
              title: 'Apple Music',
              href: APPLE,
            },
            SPOTIFY && {
              title: 'Spotify',
              href: SPOTIFY,
            },
            SOUNDCLOUD && {
              title: 'SoundCloud',
              href: SOUNDCLOUD,
            },
            ,
            AMAZON && {
              title: 'Amazon',
              href: AMAZON,
            },
          ]}
        />
      </Col>
    </Row>
    <Row>
      <Col>{FOOTER}</Col>
    </Row>
  </>
);
