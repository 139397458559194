import React from 'react'
import { Card, ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './Release.scss'
export const ReleaseCard = (props) => {
  const { page, img, title, links, id } = props
  return (
    <Card id={id} className="release">
      <Card.Body>
        {page ? (
          <Link to={page}>
            <Card.Img src={img} title={title} alt={title} />
            <Card.Title>{title}</Card.Title>
          </Link>
        ) : (
          <>
            <Card.Img src={img} title={title} />
            <Card.Title>{title}</Card.Title>
          </>
        )}
        <div>
          <ListGroup horizontal>
            {links &&
              links.map(({ href, title }) => (
                <ListGroup.Item key={title}>
                  <a href={href}>{title}</a>
                </ListGroup.Item>
              ))}
            {page && (
              <ListGroup.Item>
                <Link to={'/' + page}>More&hellip;</Link>
              </ListGroup.Item>
            )}
          </ListGroup>
        </div>
      </Card.Body>
    </Card>
  )
}
