import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Album, AlbumLinks } from '../Album';

export const Ruiner = () => (
  <>
    <Row>
      <Col xs={12} sm={8} md={8} lg={8} xl={8}>
        <Album
          img="https://is3-ssl.mzstatic.com/image/thumb/Music/v4/37/81/f2/3781f250-30bc-d10c-c04b-86d9e3826431/884501963091.jpg/1000x1000bb-60.jpg"
          title="The Ruiner"
          page="ruiner"
          body={
            <>
              <p>The final album by Lucy Bland.</p>
              <p>
                Hailing from Seattle, Washington, Lucy Bland lets the rain
                inspire their music rather than drown them out. Channeling those
                long winters and rainy days into each opus, Lucy Bland combines
                delicate drops of sound into a deluge that is indie, folk, and
                electronic all at once.
              </p>
            </>
          }
        />
      </Col>
      <Col xs={12} sm={4} md={4} lg={4} xl={4}>
        <AlbumLinks
          links={[
            {
              title: 'Apple Music',
              href: 'https://music.apple.com/us/album/the-ruiner/725977203',
            },
            {
              title: 'Spotify',
              href:
                'https://open.spotify.com/album/1fD4PVv41GtDSXqnZ25wAd?si=NrQenT7bRTqTfUPCxEbO7w',
            },
          ]}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <iframe
          width="100%"
          height="450"
          scrolling="no"
          frameborder="no"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/12870835&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false"
        ></iframe>{' '}
      </Col>
    </Row>
  </>
);
