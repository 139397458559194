import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import './Album.scss';
export const Album = (props) => {
  const { img, title, showTitle = true, body } = props;
  return (
    <Card className="album">
      <Card.Body>
        <Card.Img src={img} title={title} alt={title} />
        {showTitle && <Card.Title>{title}</Card.Title>}
        <div>{body}</div>
      </Card.Body>
    </Card>
  );
};

export const AlbumLinks = (props) => {
  const { links } = props;
  return (
    <ListGroup>
      {links &&
        links.map(
          (service) =>
            service && (
              <ListGroup.Item key={service.title}>
                <a href={service.href}>{service.title}</a>
              </ListGroup.Item>
            )
        )}
    </ListGroup>
  );
};
